@import '../../sass/colors.scss';

.banner-section {
    min-height: 600px;
    margin-top: 90px;
    position: relative;
    z-index: 1;

    .banner-pattern {
        position: absolute;
        top: 10%;
        right: 0;
        left: 0;
        width: 100%;
        z-index: -1;
    }
}

.banner-text {
    h1 {
        font-size: 64px;
        font-weight: 700;
        margin-bottom: 40px;
    }
    .banner-bottom {
        margin-top: 24px;
        display: flex;

        .theme-btn {
            margin-right: 50px;

            a {
                padding: 20px 25px;
            }
        }

        
    }
}


/* CSS */
.button-search {
    background: #583FBC;
    color: #ffffff;
    padding: 12px 20px;
    text-decoration: none;
    border-radius: 15px;
    transition: 0.3s;
    border: 1px solid transparent;
    font-size: 14px;
    font-weight: 700;
    // margin-top: 4px;
    display: inline-block;
    width: 160px
}

.search-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 30px;
}

.search-text {
    margin-bottom: 0 !important;
    margin-right: 20px;
    width: 60%;
}

.banner-call {
    display: flex;
    flex-direction: column;
    align-items: center;

    .call-text {

        

        h6 {
            font-size: 14px;
            text-transform: uppercase;
            font-weight: 700;
        }
    }
}

.item {
    display: flex;
    align-items: baseline;

    .icon {
        width: 35px;
        height: 35px;
        margin-right: 12px;
        // background: $color-white;
        line-height: 35px;
        text-align: center;
        // border-radius: 12px;
        // position: relative;
        // z-index: 1;

    }

    p {
        font-size: 12px;
        text-transform: uppercase;
        color: $color-blue;
        font-weight: 700;
        margin-bottom: 6px;
    }
}

.service-box {
    // border: 1px solid rgba(24, 25, 69, 0.1019607843);
    // padding: 15px;
    // border-radius: 16px;
    text-align: center;
    // margin-bottom: 30px;
    display: flex;
    justify-content: center;
    
}

.banner-img-area {
    position: relative;
    text-align: center;

    .banner-img {
        margin-bottom: -150px;
    }

    .info-box {
        position: absolute;
        bottom: 5%;
        left: 0%;
        background: $color-white;
        border-radius: 15px;
        padding: 10px;
        display: flex;
        align-items: center;

        .info-img {
            margin-right: 10px;
        }

        .info-text {
            text-align: left;
            
            p {
                margin-bottom: 0;
                color: $color-black;
                font-size: 16px;
                font-weight: 600;

                small {
                    color: $color-gray;
                }
            }
        }
    }

    .shapes {
        img {
            position: absolute;

            &:nth-child(1) {
                left: 0;
                top: 0;
            }
            &:nth-child(2) {
                right: 0;
                top: 0;
            }
            &:nth-child(3) {
                right: 0;
                top: 40%;
            }
            &:nth-child(4) {
                left: 10%;
                top: 30%;
                z-index: -1;
            }
        }

    }

}


// Mobile Responsive
@media (max-width: 575px) {

    // Banner CSS
    .banner-section {
        height: 100%;
        margin-top: 70px
    }
    .banner-img-area {
        .banner-img {
            margin-bottom: 0;
        }

        .shapes {
            img {
                &:nth-child(1) {
                    max-width: 70px ;
                }
            }
        }
        
    }
    .banner-text {
        text-align: center;
        padding-top: 30px;
        
        h1 {
            font-size: 30px;
            margin-bottom: 15px;
        }

        .banner-bottom {
            margin-top: 40px;
            margin-bottom: 30px;
            display: block;
    
            .theme-btn {
                margin-right: 0px;
                margin-bottom: 20px;
                
                a {
                    padding: 15px 20px;
                }
            }
    
            .banner-call {
                justify-content: center;

                .call-text {
                    text-align: left;
                    p {
                        font-size: 12px;
                    }
    
                    h6 {
                        font-size: 16px;
                    }
                }
            }
        }
        .search-text {
            margin-bottom: 20px !important;
            margin-right: 0;
            width: 100%;
        }
        
    }
    .card {
        width: 100% !important;
    }

    .card-body .row label {
        padding-left: 4px !important;
        
      }
      
      .card-body .row span {
        padding-left: 4px !important;
        
      }
}


@media (min-width: 576px) and (max-width: 767px) {
    .card {
        width: 100% !important ;
    }

    .card-body .row label {
        
        padding-left: 4px !important;
        
      }
      
      .card-body .row span {
        
        padding-left: 4px !important;
      }
    // Banner CSS
    .banner-section {
        height: 100%;
        margin-top: 70px
    }
    .banner-img-area {
        .banner-img {
            margin-bottom: 0;
        }

        .shapes {
            img {
                &:nth-child(1) {
                    max-width: 70px ;
                }
            }
        }
        
    }
    .banner-text {
        text-align: center;
        padding-top: 30px;
        
        h1 {
            font-size: 30px;
            margin-bottom: 15px;
        }

        .banner-bottom {
            margin-top: 40px;
            margin-bottom: 30px;
            display: block;
    
            .theme-btn {
                margin-right: 0px;
                margin-bottom: 20px;
                
                a {
                    padding: 15px 20px;
                }
            }
    
            .banner-call {
                justify-content: center;

                .call-text {
                    text-align: left;
                    p {
                        font-size: 12px;
                    }
    
                    h6 {
                        font-size: 16px;
                    }
                }
            }
        }
    }
}

// Medium Devices Responsive
@media (min-width: 768px) and (max-width: 991px) {
    // Banner CSS
    .banner-section {
        height: 100%;
        margin-top: 70px
    }
    .banner-img-area {
        .banner-img {
            margin-bottom: 0;
        }

        .shapes {
            img {
                &:nth-child(1) {
                    max-width: 70px ;
                }
            }
        }
        
    }
    .banner-text {
        text-align: center;
        padding-top: 30px;
        
        h1 {
            font-size: 40px;
            margin-bottom: 15px;
        }

        .banner-bottom {
            justify-content: center;
        }
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .banner-text  {
        h1 {
            font-size: 45px;
            margin-bottom: 25px;
        } 

        .banner-bottom {
            margin-top: 40px;
        }
    }  
}

  
  .card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 60%;
    max-width: 100%;
    overflow: hidden;
  }
  
  .card-header {
    background-color: #583FBC;
    color: #fff;
    padding: 15px;
    text-align: center;
    font-size: 1.2em;
  }
  
  .card-body {
    padding: 15px;
  }
  
  .card-body .row {
    display: flex;
    justify-content: space-between;
    padding: 8px 0;
    border-bottom: 1px solid #e0e0e0;
  }
  
  .card-body .row:last-child {
    border-bottom: none;
  }
  
  .card-body .row label {
    font-weight: bold;
    color: #333;
    width: 50%;
    text-align: left;
    padding-left: 40px;
    padding-right: 18px;
  }
  
  .card-body .row span {
    color: #555;
    width: 50%;
    text-align: left;
    padding-left: 40px;
    padding-right: 18px;
  }